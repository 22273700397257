<template>
  <v-container fluid class="fill-height" style="padding: 0px;">
    <v-card v-for="post in posts" :key="post" class="mb-5">
      <blogPost :post="post"></blogPost>
    </v-card>
    <!-- <v-row justify="center" align="end">
      <v-col cols="12">
        <v-card
          elevation="2"
          :color="$vuetify.theme.dark ? 'grey lighten-2' : ''"
          :class="isMobile ? 'mb-10 px-2' : ''"
        >
          <v-row align="center" justify="center">
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/onetechhub-logo.svg"
                alt=""
                width="120"
                @click="
                  goToProvider(
                    'https://www.opportunitynortheast.com/digital-entrepreneurship/one-tech-hub'
                  )
                "
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/sds-logo.png"
                alt=""
                width="120"
                @click="
                  goToProvider('https://www.skillsdevelopmentscotland.co.uk/')
                "
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/thedatalab-logo.png"
                alt=""
                width="60"
                @click="goToProvider('https://www.thedatalab.com/')"
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/uoa-logo.png"
                alt=""
                contain
                width="130"
                @click="goToProvider('https://www.abdn.ac.uk/')"
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img partner-logo-rgu"
                src="../../public/platform-logos/rgu-full-logo.png"
                alt=""
                width="130"
                @click="goToProvider('https://www.rgu.ac.uk/')"
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/codeclan-logo.png"
                alt=""
                width="130"
                @click="goToProvider('https://codeclan.com/')"
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/nescol-logo.png"
                alt=""
                width="130"
                @click="goToProvider('https://www.nescol.ac.uk/')"
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/dw-logo.png"
                alt=""
                width="60"
                @click="
                  goToProvider(
                    'https://www.digitalworld.net/industries-and-jobs'
                  )"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import blogPost from "@/components/blog/blogPost.vue";
export default {
  data() {
    return {};
  },
  components: { blogPost },
  name: "Home",
  computed: {
    posts() {
      return this.$store.getters["blogs/getBlogPosts"];
    }
  },
  isMobile() {
    if (window.innerWidth <= 1250) {
      return true;
    } else {
      return false;
    }
  },
  methods: {
    goToProvider(provider) {
      window.open(provider, "_blank");
    }
  },
  mounted() {
    let scope = this;

    this.$store.dispatch("blogs/retrieveBlogPosts").then(() => {
      setTimeout(function() {
        scope.firstLoad = false;
      }, 50);
    });
  }
};
</script>

<style scoped>
p {
  font-size: 1.3em;
  font-weight: bold;
  /* text-align: center; */
}
.partner-logo-rgu {
  background-color: #712177;
}
.center-img {
  margin: 0 auto;
  cursor: pointer;
}
</style>
