<template>
  <v-container fluid>
    <v-row class="pb-0" align="center" justify="center">
      <v-col>
        <br />
        <!-- <v-img
          max-height="500"
          max-width="700"
          :src="post.imgUrl"
          width="800px"
          class="ml-left mb-0"
        /> -->
        <v-img
          v-if="isMobile === true"
          :src="post.imgUrl"
          max-height="200"
          max-width="400"
          class="ml-left mb-0"
        />
        <v-img
          v-if="isMobile === false"
          :src="post.imgUrl"
          max-height="350"
          max-width="550"
          class="ml-left mb-0"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <h1 class="pa-2" :style="$vuetify.theme.dark ? '' : 'color: #19365e; '">
          {{ post.title }}
        </h1>
        <v-card-title
          class="pl-1"
          :style="$vuetify.theme.dark ? '' : 'color: #19365e; '"
          >{{ post.publishedAt }}</v-card-title
        >
        <p
          class="pa-2"
          v-html="post.textBody"
          :style="$vuetify.theme.dark ? '' : 'color: #19365e; '"
        ></p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ["post"],
  computed: {
    isMobile() {
      if (window.innerWidth <= 1250) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {},
};
</script>
<style scoped>
p {
  font-size: 1.3em;
  font-weight: bold;
  /* text-align: center; */
}
.partner-logo-rgu {
  background-color: #712177;
}
.center-img {
  margin: 0 auto;
  cursor: pointer;
}
</style>
